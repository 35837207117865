export enum FeatureRouteMap {
  general = "General",
  "inbound-referral" = "Inbound Referral",
  "insurance-verification" = "Insurance Verification",
  "outbound-referral" = "Outbound Referral",
  waitlist = "Waitlist",
  communication = "Communication",
  followup = "Followup",
  feedback = "Feedback",
  reminder = "Reminder",
  scheduler = "Scheduler",
  lumabot = "LumaBot",
  telehealth = "Telehealth",
  "waiting-room" = "Waiting Room",
  "forms" = "Forms",
}

export const featureKeys = Object.keys(FeatureRouteMap);

export type FeatureKeys = keyof typeof FeatureRouteMap;
