export enum PracticeResoucesRouteMap {
  providers = "Provider",
  facilities = "Facility",
  "appointment-types" = "Appt Type",
}

export const practiceResoucesKeys = Object.keys(PracticeResoucesRouteMap);

export type PracticeResoucesKeys = keyof typeof PracticeResoucesRouteMap;

