import { useState } from "react";
import { Button, Input } from "@chakra-ui/react";
import { getEnv } from "../../utils";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token") || "";
const baseEnv = getEnv().replace(/https:\/\/next./, "");
const isLocal = getEnv().includes("localhost") || getEnv().includes("3000");

let baseUrl = `https://api.${baseEnv}/api/`;
if (isLocal) {
  baseUrl = "http://localhost:8002/api/";
}

const uploadFile = async (file: File) => {
  const url = `${baseUrl}fileUploads/binary`;

  const fileNameArray = file.name.split(".");
  const fileName = fileNameArray[0];
  const fileExtension = fileNameArray[fileNameArray.length - 1];
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", fileName);
  formData.append("extension", fileExtension || "");

  const returnValue = await fetch(url, {
    headers: {
      "x-access-token": token,
      Accept: "application/json",
    },
    method: "POST",
    body: formData,
  });

  const body = await returnValue.json();
  return body;
};

const FileUpload = ({ setFileUploadId, patientId }: any) => {
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();

  return (
    <>
      <Input
        type="file"
        size="lg"
        height={32}
        px={6}
        onChange={(e) => setFile(e.currentTarget.files![0])}
      />
      <Button
        isLoading={isLoading}
        disabled={!patientId}
        height={32}
        variant="solid"
        onClick={async () => {
          setLoading(true);
          const fileUpload = await uploadFile(file!);
          setFileUploadId(fileUpload._id);
          setLoading(false);
        }}
      >
        Upload
      </Button>
    </>
  );
};

export default FileUpload;
