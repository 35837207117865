export enum MessageTemplatesRouteMap {
  appointments = "Appointments",
  feedback = "Feedback",
  offers = "Offers",
  referrals = "Referrals",
  waitlists = "Waitlists",
  estimates = 'Estimates',
  other = "Other",
}

export const messageTemplatesKeys = Object.keys(MessageTemplatesRouteMap);

export type MessageTemplatesKeys = keyof typeof MessageTemplatesRouteMap;
