import * as React from "react";
import { Box, Stack } from "@chakra-ui/react";

interface Props {
  title: string;
  show: boolean;
  toggleVisibility: () => void;
}

const OptionsWrapper: React.FC<Props> = ({ children, title, show, toggleVisibility }) => {
  return (
    <Box cursor={"pointer"} onMouseEnter={toggleVisibility} onMouseLeave={toggleVisibility}>
      <div>{`${title}`} &gt;</div>
      {show && (
        <Box
          position={"absolute"}
          left={"170px"}
          paddingBlock={12}
          bg="rgb(13, 19, 95)"
          zIndex={999}
          transform={"translateY(-25%)"}
          borderRadius="8px"
          boxShadow={"0 3px 6px rgba(0,0,0,0.16)"}
          width={200}
          color="rgba(255,255,255,0.7)"
          height={"fit-content"}
        >
          <Stack direction={"column"} alignItems="center">
            {children}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default OptionsWrapper;
