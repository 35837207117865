import { useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import FileUpload from "./CreateEstimates/FileUpload";
import { getEnv } from "../utils";

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token") || "";
const baseEnv = getEnv().replace(/https:\/\/next./, "");
const isLocal = getEnv().includes("localhost") || getEnv().includes("3000");

let baseUrl = `https://api.${baseEnv}/api/`;
if (isLocal) {
  baseUrl = "http://localhost:8002/api/";
}

const getAppointments = async (patientId: string) => {
  const params = new URLSearchParams({
    date: `<=${Date.now()}`,
    includeHiddenResources: "true",
    limit: "100",
    page: "1",
    patient: patientId,
    sort: "date",
    sortBy: "desc",
  });
  const url = `${baseUrl}appointments/crudList?${params.toString()}`;

  const returnValue = await fetch(url, {
    headers: {
      "x-access-token": token,
      Accept: "application/json",
    },
  });

  const body = await returnValue.json();

  return body.response;
};

const postEstimate = async (
  patient: string,
  appointment: string,
  fileUpload: string,
  amount: number
) => {
  const url = `${baseUrl}v2/estimates`;

  const estimateBody = {
    date: new Date(),
    totalPatientAmountInCents: amount,
    payCode: {
      source: "change-healthcare",
      value: "string",
    },
    patient,
    appointment,
    fileUpload,
    visible: true,
    source: "change-healthcare",
    searchIds: ["63600402535346001368991f"],
  };

  const returnValue = await fetch(url, {
    headers: {
      "x-access-token": token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(estimateBody),
  });

  const body = await returnValue.json();
  return body;
};

const updateFileUpload = async (
  estimateId: string,
  fileUploadId: string
) => {
  const url = `${baseUrl}v2/fileUploads/${fileUploadId}`;

  const fileUploadBody = {
    estimate: estimateId,
  };

  const returnValue = await fetch(url, {
    headers: {
      "x-access-token": token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(fileUploadBody),
  });

  const body = await returnValue.json();
  return body;
};

const CreateEstimates = () => {
  const [isLoading, setLoading] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [fileUploadId, setFileUploadId] = useState("");
  const [amount, setAmount] = useState<number>();

  const isSendEnable = patientId && appointmentId && fileUploadId && amount;

  return (
    <>
      <Box margin="20px">
        <Text fontSize="xl" fontWeight={700}>
          How to use
        </Text>
        <Text>
          1. Start by adding a patient id, it will populate the Appointment id
          field;
        </Text>
        <Text>
          2. Upload a file and hit upload. That will populate the File Upload
          Id, and send a message to the patient;
        </Text>
        <Text>3. Add an estimate amount and click on send.</Text>
      </Box>
      <Box margin="20px">
        <Stack spacing={8}>
          <InputGroup>
            <InputLeftAddon children="Patient id:" marginRight={8} />
            <Input
              flexGrow={1}
              size="lg"
              height={32}
              placeholder="Add patient id"
              px={6}
              value={patientId}
              onChange={async (e) => {
                const newPatientId = e.currentTarget.value;
                setPatientId(newPatientId);
                if (newPatientId.length === 24) {
                  setLoading(true);
                  const appointments = await getAppointments(newPatientId);
                  // @ts-ignore
                  const firstAppt = appointments[0];
                  setAppointmentId(firstAppt?._id || "");
                  setLoading(false);
                }
              }}
            />
          </InputGroup>
          <FileUpload setFileUploadId={setFileUploadId} patientId={patientId} />
          <InputGroup>
            <InputLeftAddon children="File Upload id:" marginRight={8} />
            <Input
              flexGrow={1}
              size="lg"
              height={32}
              placeholder="Add a fileUpload id"
              px={6}
              value={fileUploadId}
              disabled
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Appointment id:" marginRight={8} />
            <Input
              flexGrow={1}
              size="lg"
              height={32}
              placeholder="Add an appointment id"
              px={6}
              value={appointmentId}
              disabled
            />
          </InputGroup>
          <InputGroup>
            <InputLeftAddon children="Estimate amount:" marginRight={8} />
            <Input
              flexGrow={1}
              size="lg"
              height={32}
              placeholder="Add a the estimate amount in cents"
              px={6}
              value={amount}
              type="number"
              onChange={(e) => setAmount(Number(e.currentTarget.value))}
            />
          </InputGroup>
          <Button
            height={32}
            isLoading={isLoading}
            variant="solid"
            disabled={!isSendEnable}
            onClick={async () => {
              setLoading(true);
              const estimate = await postEstimate(
                patientId,
                appointmentId,
                fileUploadId,
                amount || 0
              );

              const estimateId = estimate?._id;
              const fileUpload = await updateFileUpload(
                estimateId,
                fileUploadId
              );

              if (fileUpload) {
                setFileUploadId("");
                setAmount(undefined);
              }

              setLoading(false);
            }}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default CreateEstimates;
