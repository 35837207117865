export const getLumaExpireMessage = () => {
  const handleLogOut = () =>
    setTimeout(() => {
      console.log("here");
      window.location.href = window.location.origin;
    }, 600000);
  let logoutTimeout = handleLogOut();
  window.addEventListener("message", (event: MessageEvent) => {
    const eventDataJson =
      event.data && typeof event.data === "string" ? event.data : null;
    let eventData;
    try {
      eventData = eventDataJson ? JSON.parse(eventDataJson) : null;
    } catch (error) {}

    const isLumaEvent = Boolean(
      eventData && eventData.messageType === "heartbeat"
    );
    if (isLumaEvent) {
      console.log("Message received", eventData);
      clearTimeout(logoutTimeout);
      logoutTimeout = handleLogOut();
    }
  });
};
