export const getEnv = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const envName = urlParams.get("env");
  if (envName === "prod") {
    return "https://next.lumahealth.io";
  } else if (envName === "designtest") {
    return "https://next-designtest.lumahealthstaging.com";
  } else if (envName === "stage") {
    return "https://next.lumahealthstaging.com";
  } else if (envName === "local") {
    return "http://localhost:3000";
  } else {
    return `https://next.${envName}.isbuildingluma.com`;
  }
};
