import React, { useState, useEffect, useCallback } from "react";
import { Box, Flex, Input, List, ListItem } from "@chakra-ui/react";

export type Option = {
  [key: string]: unknown;
  label: string;
  value: string;
};

export interface AutocompleteProps {
  onSearch: (value: string) => Promise<Option[]>;
  onSelect: any;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  onSearch,
  onSelect,
}) => {
  const [data, setData] = useState<Option[]>();
  const [ready, setReady] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setTriggerSearch(true);
  };
  const loadData = useCallback(
    async (value) => {
      if (value) {
        const results = await onSearch(value);
        setData(results);
        setReady(true);
      } else {
        setReady(false);
        setData([]);
      }
    },
    [onSearch]
  );

  const handleOnSelect = (option: Option) => {
    onSelect({ url: `patients/edit`, context: { _id: option.value } });
    setInputValue(option.label);
    setReady(false);
    setData([]);
  };

  useEffect(() => {
    let debounce: NodeJS.Timeout | undefined;
    if (triggerSearch) {
      debounce = setTimeout(() => {
        loadData(inputValue);
        setTriggerSearch(false);
      }, 300);

      if (debounce) return () => clearInterval(debounce as NodeJS.Timeout);
    }
  }, [loadData, inputValue, triggerSearch, setTriggerSearch]);

  return (
    <Box position="relative" zIndex={2}>
      <Input
        width={300}
        size="lg"
        height={32}
        placeholder="Search for a patient"
        px={6}
        value={inputValue || ""}
        onChange={(e) => handleInputChange(e.currentTarget.value)}
      />
      {ready && (
        <List
          position="absolute"
          backgroundColor="white"
          boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
          mt={2}
          p={0}
          width="100%"
        >
          {data?.map((option) => (
            <ListItem
              key={option.value}
              _hover={{ bg: "#f2f2f2" }}
              my={1}
              px={20}
              py={10}
              cursor="pointer"
              onClick={() => handleOnSelect(option)}
            >
              <Flex align="center">{option.label}</Flex>
            </ListItem>
          ))}

          {!data?.length && (
            <ListItem my={1} px={20} py={10}>
              <Flex justify="center">{"Not found"}</Flex>
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};
