import * as React from "react";
import { Grid, GridItem, Link, Stack } from "@chakra-ui/react";

import LumaIframe from "./components/LumaIframe";
import { getLumaExpireMessage } from "./utils/getLumaExpireMessage";
import {
  FeatureRouteMap,
  featureKeys,
  FeatureKeys,
  MessageTemplatesRouteMap,
  messageTemplatesKeys,
  MessageTemplatesKeys,
  PatientBillingRoutes,
  PatientBillingKey,
  patientBillingKeys,
  PracticeResoucesRouteMap,
  practiceResoucesKeys,
  PracticeResoucesKeys,
} from "./utils";
import OptionsWrapper from "./components/OptionsWrapper";
import useShowFeature from "./hooks/useShowFeature";
import {
  MessageLibraryKey,
  messageLibraryKeys,
  MessageLibraryRoutes,
} from "./utils/messageLibrary";
import PatientSearch from "./components/PatientSearch";
import CreateEstimates from "./components/CreateEstimates";

getLumaExpireMessage();

function App() {
  const [page, setPage] = React.useState<any>("home");
  const { state: options, toggleShowFeatures } = useShowFeature();
  const context = React.useMemo(() => page.context || {}, [page.context]);

  return (
    <Grid h="100vh" templateRows="75px 1fr" templateColumns="250px 1fr">
      <GridItem
        borderBottom="1px solid rgb(225, 65, 82)"
        rowSpan={1}
        colSpan={2}
        bg="white"
        display="flex"
        alignItems="center"
        padding={16}
      >
        <img
          alt="Luma Health"
          src="https://www.lumahealth.io/wp-content/themes/LumaHealth2022/assets/images/logo.svg"
        />
        <PatientSearch onSelect={setPage} />
      </GridItem>
      <GridItem
        bg="rgb(13, 19, 85)"
        color="white"
        display="flex"
        flexDirection="column"
        padding={24}
        rowSpan={2}
        colSpan={1}
      >
        <Stack>
          <Link cursor="pointer" onClick={() => setPage("estimate")}>
            Create Estimate
          </Link>
          <Link cursor="pointer" onClick={() => setPage("home")}>
            Home
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "broadcasts", context: { test: "test" } })
            }
          >
            Broadcasts
          </Link>
          <Link
            cursor="pointer"
            onClick={() => setPage({ url: "hub", context: { test: "test" } })}
          >
            Hub
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "schedule", context: { test: "test" } })
            }
          >
            Schedule
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "waiting-room", context: { test: "test" } })
            }
          >
            Waiting room
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "uploadcenter", context: { test: "test" } })
            }
          >
            Upload Center
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "file-management", context: { test: "test" } })
            }
          >
            File Management
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "referrals", context: { test: "test" } })
            }
          >
            Inbound Referrals
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "outbound-referrals", context: { test: "test" } })
            }
          >
            Outbound Referrals
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "waitlists", context: { test: "test" } })
            }
          >
            Waitlist
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({
                url: "settings/home/my-account",
                context: { test: "test" },
              })
            }
          >
            My Account
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({
                url: "settings/home/main-account",
                context: { test: "test" },
              })
            }
          >
            Main Account
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "patients/add", context: { test: "test" } })
            }
          >
            Add Patient
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({
                url: "settings/utilities/branding/patient-form",
                context: { test: "test" },
              })
            }
          >
            Patient Branding
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({
                url: "messaging-activity/summary",
                context: { test: "test" },
              })
            }
          >
            Messaging Activity
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "reports", context: { test: "test" } })
            }
          >
            Reports
          </Link>
          <OptionsWrapper
            title="Practice Resources"
            show={options.practiceResources}
            toggleVisibility={() => toggleShowFeatures("practiceResources")}
          >
            {practiceResoucesKeys.map((key) => (
              <Link
                key={key}
                _hover={{ color: "white" }}
                paddingInline={4}
                paddingBlock={6}
                cursor="pointer"
                onClick={() =>
                  setPage({
                    url: `settings/practice-resources/${key}`,
                    context: { test: "test" },
                  })
                }
              >
                {PracticeResoucesRouteMap[key as PracticeResoucesKeys]}
              </Link>
            ))}
          </OptionsWrapper>

          <OptionsWrapper
            title="Patient Billing"
            show={options.patientBilling}
            toggleVisibility={() => toggleShowFeatures("patientBilling")}
          >
            {patientBillingKeys.map((key) => (
              <Link
                key={key}
                _hover={{ color: "white" }}
                paddingInline={4}
                paddingBlock={6}
                cursor="pointer"
                onClick={() =>
                  setPage({
                    url: `settings/utilities/patient-billing/${key}`,
                    context: { test: "test" },
                  })
                }
              >
                {PatientBillingRoutes[key as PatientBillingKey]}
              </Link>
            ))}
          </OptionsWrapper>
          <OptionsWrapper
            title="Message Library"
            show={options.messageLibrary}
            toggleVisibility={() => toggleShowFeatures("messageLibrary")}
          >
            {messageLibraryKeys.map((key) => (
              <Link
                key={key}
                _hover={{ color: "white" }}
                paddingInline={4}
                paddingBlock={6}
                cursor="pointer"
                onClick={() =>
                  setPage({
                    url: `settings/utilities/message-library/${key}`,
                    context: { test: "test" },
                  })
                }
              >
                {MessageLibraryRoutes[key as MessageLibraryKey]}
              </Link>
            ))}
          </OptionsWrapper>
          <OptionsWrapper
            title="Features"
            show={options.features}
            toggleVisibility={() => toggleShowFeatures("features")}
          >
            {featureKeys.map((key) => (
              <Link
                key={key}
                _hover={{ color: "white" }}
                paddingInline={4}
                paddingBlock={6}
                cursor="pointer"
                onClick={() =>
                  setPage({
                    url: `settings/features/${key}`,
                    context: { test: "test" },
                  })
                }
              >
                {FeatureRouteMap[key as FeatureKeys]}
              </Link>
            ))}
          </OptionsWrapper>
          <OptionsWrapper
            toggleVisibility={() => toggleShowFeatures("messageTemplates")}
            title="Message Templates"
            show={options.messageTemplates}
          >
            {messageTemplatesKeys.map((key) => (
              <Link
                key={key}
                _hover={{ color: "white" }}
                paddingInline={4}
                paddingBlock={6}
                cursor="pointer"
                onClick={() =>
                  setPage(`settings/utilities/message-templates/${key}`)
                }
              >
                {MessageTemplatesRouteMap[key as MessageTemplatesKeys]}
              </Link>
            ))}
          </OptionsWrapper>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({ url: "form-builder", context: { test: "test" } })
            }
          >
            Form Builder
          </Link>
          <Link
            cursor="pointer"
            onClick={() =>
              setPage({
                url: "settings/utilities/custom-actions",
                context: { test: "test" },
              })
            }
          >
            Custom Actions
          </Link>
        </Stack>
      </GridItem>
      <GridItem>
        {page === "home" ? (
          "Home"
        ) : page === "estimate" ? (
          <CreateEstimates />
        ) : (
          <LumaIframe page={page} context={context} />
        )}
      </GridItem>
    </Grid>
  );
}

export default App;
