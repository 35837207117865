import * as React from "react";

interface IOptions {
  features: boolean;
  messageTemplates: boolean;
  patientBilling: boolean;
  messageLibrary: boolean;
  practiceResources: boolean;
}

type featureKey = keyof IOptions;

const useShowFeature = () => {
  const [state, dispatch] = React.useReducer(
    (state: IOptions, action: Partial<IOptions>) => ({ ...state, ...action }),
    {
      features: false,
      messageTemplates: false,
      patientBilling: false,
      messageLibrary: false,
      practiceResources: false,
    }
  );
  const toggleShowFeatures = (feature: featureKey) => {
    dispatch({ [feature]: !state[feature] });
  };

  return { state, toggleShowFeatures };
};

export default useShowFeature;
